<template>
  <ion-header>
    <ion-row>
      <ion-col>
        <ion-row class="ion-justify-content-between ion-align-items-center">
          <ion-col>
            <ion-row v-if="ordineDiServizio">
              <ion-col size="8">
                <h2>{{ ordineDiServizio.titolo }}</h2>
              </ion-col>
              <ion-col size="4">
                <img
                  src="../../../public/assets/img/avatar_placeholder.png"
                  style="
                    border-radius: 50%;
                    width: 60px;
                    height: 60px;
                    margin-top: 15px;
                  "
                  @error="handleImgError"
                />
              </ion-col>
              <ion-col size="12">
                <!-- <strong>Data</strong>:
            {{
              new Date(ordineDiServizio.dataOra_inizio).toLocaleDateString("it")
            }}
            {{
              new Date(ordineDiServizio.dataOra_inizio).toLocaleTimeString(
                "it",
                { hour: "2-digit", minute: "2-digit" }
              )
            }}<br /> -->
                <strong>Oggetto</strong>: {{ ordineDiServizio.titolo }}<br />
              </ion-col>
            </ion-row>
            <span class="categoria">{{ item.product_text }}</span>
            <!-- <h4>{{ item.numeroCommessa }} - {{ item.nomeCliente }}</h4> -->
          </ion-col>
          <ion-col size="auto">
            <ion-icon
              class="arrow-down"
              @click="closeModal"
              :icon="close"
            ></ion-icon>
          </ion-col>
        </ion-row>

        <!-- <ion-row class="ion-justify-content-between  ">
          <ion-col>
            <ion-row style="font-size: 01rem" class="ion-justify-content-between mt-3">
              <ion-col class="ion-no-padding" size="auto"> Vendita: </ion-col>
              <ion-col class="ion-no-padding" size="6"> € {{ kpiValue }} </ion-col>
            </ion-row>
            <ion-row style="font-size: 01rem" class="ion-justify-content-between">
              <ion-col class="ion-no-padding" size="auto"> Margine: </ion-col>
              <ion-col class="ion-no-padding" size="6">
                € {{ margineValue }}
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col v-if="item.id_status == 1 || item.id_status == 2" size="auto">
            <ion-button @click="setStatoAnnulla">Annulla</ion-button>
          </ion-col>
        </ion-row> -->
      </ion-col>
    </ion-row>
  </ion-header>

  <ion-content id="messages-wrapper" class="ion-padding messages-wrapper">
    <ion-row
      class="message"
      v-for="message in messages"
      :key="message.id"
      :class="message.userType == 'U' ? 'my-message' : 'others-message'"
    >
      <ion-item lines="none" size="8">
        <ion-col>
          <ion-row>
            <ion-col size="12">
              {{ message.message }}
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <template v-for="file in message.files" :key="file">
                <template v-if="file.file_url.match(/.(jpg|jpeg|png|gif)$/i)">
                  <img :src="file.file_url" @error="handleImgError" />
                </template>
                <template v-else>
                  <a :href="file.file_url" target="_blank">
                    {{ file.file_url }}
                  </a>
                </template>
              </template>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-item>
    </ion-row>
    <ion-row class="bottom"> </ion-row>
  </ion-content>

  <ion-footer>
    <ion-card class="new-message no-background">
      <ion-row class="ion-align-items-end">
        <ion-col size="12" class="form">
          <ion-row class="ion-align-items-center">
            <ion-col>
              <ion-item class="ion-align-items-end" lines="none">
                <ion-textarea
                  v-model="value"
                  autocapitalize
                  autoGrow
                  autoFocus
                  placeholder="Type your message..."
                  @ionFocus="scrollDown"
                  @keyup.enter="sendMessage"
                ></ion-textarea>
                <div class="imput">
                  <label>
                    <ion-icon :icon="attach" @click="addFiles()"></ion-icon>
                    <ion-badge
                      class="attachment-counter"
                      v-if="files.length != 0"
                      >{{ files.length }}
                    </ion-badge>
                  </label>
                  <input
                    type="file"
                    ref="files"
                    multiple
                    @change="handleFilesUpload($event)"
                  />
                </div>
              </ion-item>
            </ion-col>
            <ion-col size="2" class="send-icon">
              <ion-icon v-if="loading" :icon="pauseCircleOutline"></ion-icon>
              <ion-icon v-else @click="sendMessage" :icon="send"></ion-icon>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <!-- <ion-row class="ion-justify-content-center">
        <ion-col size="auto">
          <ion-button @click="risolto"> Done </ion-button>
        </ion-col>
      </ion-row> -->
    </ion-card>
  </ion-footer>
</template>

<script>
import {
  IonButtons,
  IonModal,
  IonInput,
  IonLabel,
  IonBadge,
  IonContent,
  IonHeader,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonTextarea,
  IonItem,
  IonCard,
  modalController,
} from "@ionic/vue";

import { defineComponent } from "vue";
import {
  send,
  attach,
  close,
  folder,
  checkmarkDone,
  image,
  pauseCircleOutline,
} from "ionicons/icons";
import { useRoute } from "vue-router";
import UserService from "../../common/mixins/User";
import ApiService from "../../common/service.api";

export default defineComponent({
  components: {
    IonButtons,
    IonModal,
    IonInput,
    IonLabel,
    IonBadge,
    IonContent,
    IonHeader,
    IonFooter,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonIcon,
    IonButton,
    IonTextarea,
    IonItem,
    IonCard,
  },
  name: "AxertaChat",

  data() {
    return {
      // kpiValue: this.item.kpiValue,
      // margineValue: this.item.margineValue,
      isReadItem: { ...this.item },
      send,
      checkmarkDone,
      attach,
      close,
      messages: [],
      value: "",
      folder,
      pauseCircleOutline,
      image,
      files: [],
      timer: "",
      message:
        "This modal example uses triggers to automatically open a modal when the button is clicked.",
      route: null,
      ordineDiServizio: null,

      loading: false,
    };
  },
  props: {
    item: Object,
  },
  mixins: [UserService],
  created() {
    this.route = useRoute();
    ApiService.get(
      "DossierManager/GetOrderChat/" +
        this.item.idDossier +
        "/" +
        this.item.idCie
    ).then((res) => {
      res.data.forEach((element) => {
        this.messages.push(element);
      });
    });
    this.getOrdineDiServizio();
    this.getCurrentUser().then((res) => {
      this.user = res;
    });
    let firsttime = true;
    let previousCount = null;
    let getApiService = () => {
      ApiService.get(
        "DossierManager/GetOrderChat/" +
          this.item.idDossier +
          "/" +
          this.item.idCie
      ).then((res) => {
        const messages = [];
        res.data.forEach((element) => {
          messages.push(element);
        });
        const changed = previousCount != messages.length;
        previousCount = messages.length;
        if (changed) {
          this.messages = messages;
          this.scrollDown(firsttime);
          // ApiService.get("SalesChat/GetOrder/" + this.item.id).then((res) => {
          //   // this.kpiValue = res.data.kpiValue;
          //   // this.margineValue = res.data.margineValue;
          // });
        }
        firsttime = false;
      });
    };
    getApiService();
    this.timer = setInterval(() => {
      getApiService();
    }, 10000);

    //     this.isReadItem.is_read = true;

    // ApiService.put("SalesChat/ReadMessage/" + this.item.id).then((res) => {
    //   console.log(res);
    // });
  },

  mounted() {
    this.scrollDown();
  },

  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    getOrdineDiServizio() {
      ApiService.get(
        "DossierManager/GetOrdineDiServizio/" + this.route.params.idOrdine
      ).then((res) => (this.ordineDiServizio = res.data));
    },
    cancel() {
      this.$refs.modal.$el.dismiss(null, "cancel");
    },
    confirm() {
      const name = this.$refs.input.$el.value;
      this.$refs.modal.$el.dismiss(name, "confirm");
    },
    onWillDismiss(ev) {
      if (ev.detail.role === "confirm") {
        this.message = `Hello, ${ev.detail.data}!`;
      }
    },
    setStatoAnnulla() {
      // SalesChat/ChangeStatus/{idOrder}/{idStatus}

      ApiService.put(`SalesChat/ChangeStatus/${this.item.id}/4`).then((res) => {
        console.log(res);
        this.closeModal();
      });
    },
    addFiles() {
      this.$refs.files.click();
    },
    handleFilesUpload(event) {
      let filesArray = Array.from(event.target.files);
      console.log(
        "🚀 ~ file: AxertaChat.vue:338 ~ handleFilesUpload ~ filesArray:",
        filesArray
      );
      filesArray.forEach((element) => {
        this.files.push(element);
      });
    },
    scrollDown(firsttime) {
      setTimeout(() => {
        document
          .querySelector("#messages-wrapper")
          .lastChild.scrollIntoView(firsttime ? { behavior: "smooth" } : {});
      }, 1);
    },
    closeModal() {
      // let response = {
      //   ticketId: 0,
      // };
      return modalController.dismiss();
    },

    closeModalRisolto() {
      let response = {
        ticketId: Number(this.id),
      };
      return modalController.dismiss(response);
    },
    sendMessage(e) {
      this.loading = true;
      e.preventDefault();
      if (this.value.trim().length > 0) {
        let message = {};
        message.id = null;
        message.data = new Date().toJSON();
        message.message = this.value;
        message.userType = "U";
        message.id_user = this.user.id;
        message.files = [];
        const promises = [];
        for (let i = 0; i < this.files.length; i++) {
          const formData = new FormData();
          formData.append("file", this.files[i]);
          promises.push(
            ApiService.post("Upload", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
          );
        }
        Promise.all(promises).then((values) => {
          values.map((res) => {
            message.files.push({ file_url: res.data });
          });
          ApiService.post(
            "DossierManager/NewMessage/" +
              this.item.idDossier +
              "/" +
              this.item.idCie,
            message
          )
            .then((res) => {
              if (res) {
                this.messages.push(message);
                this.scrollDown();
                this.value = "";
                this.files = [];
              }
            })
            .finally(() => {
              this.loading = false;
            });
        });
      } else {
        return;
      }
    },
    // async risolto() {
    //   this.closeModal();
    //   // let ticket;
    //   // const id = this.id;
    //   // const getTicket = async function () {
    //   //   await ApiService.get("HelpDesk/" + id).then((res) => {
    //   //     ticket = JSON.parse(JSON.stringify(res.data));
    //   //     ticket.solved = true;
    //   //     ticket.status_text = "Closed";
    //   //     ticket.need_reply = false;
    //   //     ticket.id_status = 2;
    //   //   });

    //   //   await putTicket();
    //   //   return;
    //   // };

    //   // const putTicket = async function () {
    //   //   ApiService.put("HelpDesk/" + id, ticket).then((res) => {
    //   //     console.log(res);
    //   //   });
    //   // };

    //   // getTicket();
    //   // this.closeModalRisolto();
    // },
  },
});
</script>

<style scoped>
/* * {
  border: 1px solid rgb(150, 246, 150);
} */

.bottom {
  margin-top: 20px;
}

input[type="file"] {
  position: absolute;
  top: -500px;
}

.form {
  margin-right: 16px;
  justify-content: space-between;
}

.form ion-item {
  border-radius: 10px;
  border: 2px solid rgb(236, 236, 236);
}

.messages-wrapper {
  width: 100%;
  overflow: scroll;
  margin: 1rem 0;
  padding: 1rem 0.5rem;
  --background: rgb(219, 233, 228);
}

.message {
  border-radius: 1.5rem;
  padding: 0.5rem;
  margin-top: 1rem;
  color: black;
}

.message ion-item {
  --background: none;
}

.my-message {
  margin-left: 3rem;
  border-bottom-right-radius: 0;
  background-color: #c2d5d9;
}

.others-message {
  margin-right: 3rem;
  border-bottom-left-radius: 0;
  background-color: #e8f3f1;
}

.new-message {
  margin: 16px;
  max-height: 25vh;
}

ion-icon {
  font-size: 2rem;
  color: rgb(141, 141, 141);
}

.send-icon {
  display: grid;
  place-items: center;
  font-size: 1.5rem;
}

.arrow-down {
  align-self: end;
  margin: 8px;
}

.attachment-counter {
  padding: 0.5rem;
  color: white;
  background: green;
  position: absolute;
  border-radius: 50%;
  bottom: 10px;
  right: 5px;
  font-size: 0.7rem;
  z-index: 1000;
}

ion-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.categoria {
  font-size: 0.7rem;
  font-weight: bolder;
  color: var(--ion-color-secondary);
}

.red {
  border: 1px solid red;
}
</style>
